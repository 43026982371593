"use strict";

const moment = require('moment');
const short = require('short-uuid');
const _ = require('lodash');
const dateManager = {
  formatWithShortenedAdjacentDays: function (date) {
    var UTC_Date = moment(date);
    var now = moment();
    var today_start = moment(now).startOf('day');
    var yesterday_start = moment(today_start).subtract(1, 'day');
    var year_start = moment(now).startOf('year');
    var formatted_date;
    if (UTC_Date.isAfter(today_start)) {
      formatted_date = "Today, " + UTC_Date.format("h:mma");
    } else if (UTC_Date.isBetween(yesterday_start, today_start)) {
      formatted_date = "Yesterday, " + UTC_Date.format("h:mma");
    } else if (UTC_Date.isAfter(year_start)) {
      formatted_date = UTC_Date.format("MMM D, h:mma");
    } else {
      formatted_date = UTC_Date.format("MMM D, YYYY, h:mma");
    }
    return formatted_date;
  },
  versionString_fromTimestamp_withOptions: function versionString_fromTimestamp_withOptions(timestamp) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const optionsWithDefaults = _.defaults({}, options, {
      referenceDate: new Date('2020-01-01T00:00:00Z')
    });
    const versionAgeInSeconds = Math.floor((new Date(timestamp) - optionsWithDefaults.referenceDate) / 1000);

    //# negative numbers have the same shortUUID as their positive equivalents so append 'a' to get
    //# a unique shortUUID
    const uuidFormattedVersionAge = versionAgeInSeconds < 0 ? Math.abs(versionAgeInSeconds) + 'a' : versionAgeInSeconds.toString();
    return short().fromUUID(uuidFormattedVersionAge);
  }
};
module.exports = dateManager;