"use strict";

const rubyLogger = require('@rubyapps/ruby-logger');
const packageName = require('../../package.json').name;
const logger = rubyLogger.getLogger(packageName);
module.exports = {
  children: function () {
    if (!this.props.__formattedChildren) {
      //logger.warn(`No __formattedChildren for component [${this.getID()}]. This shouldn't happen as we expect this to be provided by ruby-component-builder`);

      return [];
    }
    const {
      default: rubyComponentBuild
    } = require('@rubyapps/ruby-component-builder/src/common/index');
    const idPrefix = this.getID();
    const spec = this.props;
    const theComponentName = spec.key || this.componentName;
    const compoundID = spec.id ? spec.id : idPrefix ? `${idPrefix}.${theComponentName}` : theComponentName;
    const childrenDepth = this.props.__depth + 1;
    const childrenComponents = _.map(this.props.__formattedChildren, (childSpec, index) => {
      const builtChildComponent = rubyComponentBuild(childSpec, `${compoundID}[${index}]`, childrenDepth, index, {
        _parent: this
      });
      return builtChildComponent;
    }).filter(rubyComponentChild => rubyComponentChild != undefined);
    return childrenComponents;
  }

  //# NOTE: currently only used by field-form
  /**
   *  @param {function} options.omitFieldPicker
   *  @param {Boolean} options.deep - if true, returns all nested keypaths
   *  @param {Boolean} options.inverted - if true, returns the omitted keys. this is a shortcut to get the list of keypaths to call (ruby-lodash).deleteBy
   *  @param {String} options.template_keyword - ovrride template keyword or we retreive from parentRouteComponent
   *
   */,
  limitedSelfKeypaths: function () {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const effectiveSpec = this.props.__originalSpec || this.props;
    if (!effectiveSpec.componentName) {
      console.error(`No formJS spec found for component: [${this.getID()}] props:`, this.props);
      return [];
    }
    const selfKeypaths = this.limitedKeypathsForNodes([effectiveSpec]);
    const routeParent = this.findAncestorBy(el => el.getRouteElement);
    const template_keyword = options.template_keyword || this.parentRouteComponent().getState().currentTemplateKey;
    const {
      contentPropertyHelper
    } = this.getDependencies();

    //# need to pick only children keys  otherwise, the other keys stored here
    //# would override the actual fields

    //const fieldSpecs = contentPropertyHelper.specsFromNodes_forTemplateKey([effectiveSpec], template_keyword);
    //console.log(`===== [limitedSelfKeypaths] fieldSpecs`, fieldSpecs)

    const propertySpecMap = contentPropertyHelper.getPropertySpecMapForTemplate(template_keyword, routeParent);
    const propertySpecMap_limited = _.pickBy(propertySpecMap, (value, propSpecMap__key) => {
      return _.some(selfKeypaths, selfKeypath => {
        //# if the selfKeypath is the prefix 
        return propSpecMap__key.indexOf(selfKeypath) == 0;
      });
    });
    if (options.deep) {
      const omittedKeypaths = [];
      let propertySpecMap_limitedAndOmitted = propertySpecMap_limited;
      if (options.omitFieldPicker) {
        propertySpecMap_limitedAndOmitted = _.omitBy(propertySpecMap_limited, (propSpecs, keypath) => {
          return _.some(propSpecs, propSpec => {
            const isOmitted = options.omitFieldPicker({
              props: propSpec
            });
            if (isOmitted) {
              omittedKeypaths.push(keypath);
            }
            return isOmitted;
          });
        });
      }
      if (options.inverted) {
        return omittedKeypaths;
      }
      return Object.keys(propertySpecMap_limitedAndOmitted);
    } else {
      //# shallow retention of keys
      return selfKeypaths;
    }
  },
  limitedChildrenKeypaths: function () {
    const effectiveChildrenSpec = this.props.__formattedChildren;
    if (!effectiveChildrenSpec) {
      console.error(`No __formattedChildren specs found for component: [${this.getID()}] props:`, this.props);
      return [];
    }
    return this.limitedKeypathsForNodes(effectiveChildrenSpec);
  },
  limitedKeypathsForNodes: function (nodes) {
    const {
      contentPropertyHelper
    } = this.getDependencies();
    //# need to pick only children keys  otherwise, the other keys stored here
    //# would override the actual fields
    const fieldSpecs = contentPropertyHelper.specsFromNodes_forTemplateKey(nodes, '');
    //# flatten
    //
    const flattenedFieldSpecs = contentPropertyHelper.nearestKeyedNodesFromSpecs(fieldSpecs);
    const limitedKeypaths = flattenedFieldSpecs.map(n => n.dataPath);
    const limitedParentKeypaths = Object.keys(limitedKeypaths.reduce((collector, keypath) => {
      const parentKeypathArr = keypath.split('.');
      if (parentKeypathArr.length > 1) {
        collector[parentKeypathArr[0]] = parentKeypathArr[0];
      }
      return collector;
    }, {})); //# NOTE: this is uniquely for matter_profile because for some reason
    //# limitKeypaths = ['matter_profile.*.type'...] but excludes 'matter_profile'
    //# whereas normal repeaters only includes the parent key 
    //# Hacky just for matter profiles

    return limitedParentKeypaths.concat(limitedKeypaths);
  }
  //# TODO: need to be able to get descendent keypaths
};