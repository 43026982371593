"use strict";

var _redux = require("redux");
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const rubyLogger = require('@rubyapps/ruby-logger');
const packageName = require('../../package.json').name;
const logger = rubyLogger.getLogger(`${packageName}`);
const _ = require('lodash');
const React = require('react');
const path = require('path');
const Route = require('route-parser');
const defaultMergeProps = (stateProps, dispatchProps, parentProps) => _extends({}, stateProps, dispatchProps, parentProps);
const baseElement = {
  //# mixins: []
  //# propTypes:
  //== RUBY COMPONENT ======================================================//
  props: {},
  _reduxManager: undefined,
  setReduxManager: function (reduxManager) {
    this._reduxManager = reduxManager;
  },
  getReduxManager: function () {
    const reduxManager = this._reduxManager;
    if (reduxManager) {
      return reduxManager;
    }
    const root = this.getRoot();
    return root._reduxManager ? root._reduxManager : undefined;
  },
  getStore: function () {
    const reduxManager = this._reduxManager;
    if (reduxManager) {
      return reduxManager.getStore();
    }
    const root = this.getRoot();
    return root._reduxManager ? root._reduxManager.getStore() : undefined;
  },
  getState: function (appState) {
    const selfSelector = this.getDefaultSelector();
    const store = this.getStore();
    const targetAppState = appState || (store ? store.getState() : undefined);
    return targetAppState ? selfSelector(targetAppState) : undefined;
  },
  getStateAtKeypath: function (keypath, appState) {
    const selfSelector = this.getDefaultSelector();
    const store = this.getStore();
    return _.get(selfSelector(appState || store.getState()), keypath);
  },
  _flattedMixins: undefined,
  getMixins: function () {
    return this._flattenedMixins;
  },
  _ownedIndex: undefined
  //# if the rubyComponent is owned (ie. returned by the rubyComponent.children() method), then they would be tagged with an _ownedIndex
  //# which would be used to re-order the reactElements on getChildrenReactElement() call
  //#, getDefaultProps: (props)=>({}) //# define if you have defaultProps
  ,
  _parent: undefined,
  getParent: function () {
    return this._parent;
  },
  getRoot: function () {
    let currentParent = this;
    do {
      let tempParent = currentParent.getParent();
      if (_.isNil(tempParent)) {
        return currentParent;
      }
      currentParent = tempParent;
    } while (!_.isNil(currentParent));
  },
  getActiveRouteComponent: function () {
    const rootComponent = this.getRoot();
    const activeRouteComponents = rootComponent.findDescendentsBy(element => element.getRouteElement && element.getState().routeParams.routeActive);
    if (activeRouteComponents.length > 1) {
      logger.warn(`More than one active route found. Will manually check each route and match against the actual window.location. Route Component IDs: `, activeRouteComponents.map(n => n.getID()));
      const activeRoute_bestMatch = _.find(activeRouteComponents, activeRouteComponent => {
        return window.location.pathname == rootComponent.getUrlForComponent_fromModule_withParams(activeRouteComponent, activeRouteComponent);
      });
      if (activeRoute_bestMatch) {
        return activeRoute_bestMatch;
      }
      logger.warn(`Cannot find activeRouteComponent by matching the expected url, going to fallback to using the last activeRouteComponent`);
    }
    return activeRouteComponents[activeRouteComponents.length - 1];
  },
  findAncestorBy: function (predicate) {
    let currentParent = this;
    do {
      let tempParent = currentParent.getParent();
      if (predicate(currentParent)) {
        return currentParent;
      }
      currentParent = tempParent;
    } while (!_.isNil(currentParent));
    return undefined;
  },
  findAncestorsBy: function (predicate) {
    let currentParent = this;
    let collectedAncestors = [];
    do {
      let tempParent = currentParent.getParent();
      if (predicate(currentParent)) {
        collectedAncestors.push(currentParent);
      }
      currentParent = tempParent;
    } while (!_.isNil(currentParent));
    return collectedAncestors;
  }
  //# TODO: defaulting includeSelf=true for now because of assumed functionality by the callers
  ,
  iterativelyTraverseAncestors_withCallback: function (callback) {
    let includeSelf = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    includeSelf && callback(this);
    const parent = this.getParent();
    if (parent) {
      parent.iterativelyTraverseAncestors_withCallback(callback);
    }
  },
  _children: [],
  _explicitChildren: [] //# any children declared that's not owned
  ,
  newMergedExplicitAndImplicitChildren: function () {
    const self = this;
    //# assumes that explicitChildren has been set
    const explicitChildren = this._explicitChildren;
    let childrenElements = explicitChildren.slice();
    if (self.children) {
      const internalChildren = self.children({
        forceNew: true
      });
      //# reassign order of children ruby-components
      if (internalChildren.length && _.isArray(internalChildren[0])) {
        const headerChildren = (internalChildren[0] ? internalChildren[0] : []).map((rubyComponent, index) => {
          rubyComponent._ownedIndex = index;
          return rubyComponent;
        });

        //# mark the header rubyComponents with some flag for when getChildrenReactElements() is called
        const footerChildren = (internalChildren[1] ? internalChildren[1] : []).map((rubyComponent, index) => {
          rubyComponent._ownedIndex = -1 * (index + 1);
          return rubyComponent;
        });
        //# mark the footer rubyComponents with some flag for when getChildrenReactElements() is called
        childrenElements = headerChildren.concat(childrenElements, footerChildren);
      } else {
        childrenElements = childrenElements.concat(internalChildren);
      }
    }
    return childrenElements;
  }
  //# expects implcit children to be changed
  //# need to create new array of children accounting for new implicit children
  //# and overwrite the children cache
  //# returns the old children if we're replacing them
  //# NOTE: some of the children might still be reused
  ,
  /*(bool|[RubyComponent])*/replaceChildren: function () {
    this.triggerTree_onReduxTearDown(); //# NOTE: ideally we want to be able to skip calling on this, but calling on this.newMergedExplicitAndImplicitChildren() causes sideeffects
    this.triggerTree_onUnmount();
    //# Reference the DynamicForm component
    //# so we need to always call on teardown

    this.clearDependencies();
    //# Replace the children rubyComponents
    const newChildren = this.newMergedExplicitAndImplicitChildren();
    const isNewChildrenSame = newChildren.length == this._children.length && newChildren.reduce((collector, newChild, index) => {
      return collector && newChild == this._children[index];
    }, true);

    //console.log(`[isNewChildrenSame] for id: [${this.getID()}]. isNewChildrenSame: ${isNewChildrenSame}`);
    if (isNewChildrenSame) {
      //# we still need to retrigger onReduxInit
      //# the only benefit of checking for children differents if that we don't 
      //# need to rebuild reducer
      //# let components know of update
      if (this.getStore() == undefined || !this.getState()) {
        return false;
      }
      this.triggerTree_onReduxInit(this.getStore().dispatch);
      this.triggerTree_onMount();
      return false;
    }
    newChildren.forEach(child => {
      child._parent = this;
    });
    const oldChildren = this._children;
    this._children = newChildren;
    this._childrenReactElements = undefined;

    //# invalidate the cached childrenids
    this._cachedChildrenIds = undefined;

    //# Rebuild the reducer
    const reduxManager = this.getReduxManager();
    if (reduxManager) {
      reduxManager.rebuildReducer();
    } else {
      logger.warn('reduxManger not found.');
    }

    //# let components know of update
    const store = this.getStore();
    this.triggerTree_onReduxInit(store.dispatch);
    this.triggerTree_onMount();
    return oldChildren;
  },
  getChildren: function () {
    return this._children;
  },
  _cachedChildrenIds: undefined,
  getChildrenIds: function () {
    if (this._cachedChildrenIds) {
      return this._cachedChildrenIds;
    }
    const children = this.getChildren();
    const flatMappedChildren = _.flatMap(children);
    const childrenIds = flatMappedChildren.map(child => child.getID());
    this._cachedChildrenIds = childrenIds;
    return this._cachedChildrenIds;
  },
  findChildBy: function (predicate) {
    return _.find(this._children, function (rcElement) {
      return predicate(rcElement);
    });
  },
  findChildrenBy: function (predicate) {
    return _.filter(this._children, function (rcElement) {
      return predicate(rcElement);
    });
  },
  findDescendentBy: function (predicate) {
    const foundChild = this.findChildBy(predicate);
    if (foundChild) {
      return foundChild;
    }
    const foundChildArr = _.reduce(this._children, function (collector, rcChild) {
      const foundGrandChild = rcChild.findDescendentBy(predicate);
      if (foundGrandChild) {
        collector.push(foundGrandChild);
      }
      return collector;
    }, []);
    return foundChildArr[0];
  },
  findDescendentsBy: function (predicate) {
    const foundChildren = this.findChildrenBy(predicate);
    const children = this.getChildren();
    const foundChildrenArr = _.reduce(children, function (collector, rcChild) {
      const foundGrandChild = rcChild.findDescendentsBy(predicate);
      if (foundGrandChild) {
        collector = collector.concat(foundGrandChild);
      }
      return collector;
    }, foundChildren);
    return foundChildrenArr;
  },
  findChildByID: function (id) {
    return this.findChildBy(rcElement => rcElement.getID() == id);
  },
  findDescendentByID: function (id) {
    //var selector = ':has(:root > :root > .id:val("'+id+'"))'
    //var descendentElement = jsonselect.match(selector, this._children)[0];

    const foundChild = this.findChildByID(id);
    if (foundChild) {
      return foundChild;
    }
    const foundChildArr = _.reduce(this._children, function (collector, rcChild) {
      const foundGrandChild = rcChild.findDescendentByID(id);
      if (foundGrandChild) {
        collector.push(foundGrandChild);
      }
      return collector;
    }, []);
    return foundChildArr[0];
  },
  getChildAtKeypathArr: function (keypathArr) {
    var currentElement = this;
    if (currentElement.getID() != keypathArr[0]) {
      return undefined;
    } else if (keypathArr.length == 1) {
      return currentElement;
    }
    for (var i = 1; i < keypathArr.length; i++) {
      var currentKey = keypathArr[i];
      if (currentElement) {
        currentElement = currentElement.findChildByID(currentKey);
      }
    }
    return currentElement;
  }
  //# TODO: defaulting includeSelf=true for now because of assumed functionality by the callers
  ,
  iterativelyTraverseChildren_withCallback: function (callback) {
    let includeSelf = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    includeSelf && callback(this);
    var children = this.getChildren();
    _.each(children, function (child) {
      child.iterativelyTraverseChildren_withCallback(callback);
    });
  },
  _chainedSelectorSpecificationFromPathString: function (path) {
    const splitPathArr = path.split(' ').filter(value => value != '');
    const groupedByCommands = splitPathArr.reduce((collector, value) => {
      if (value == '>' || value == '<') {
        const newCommandArgs = [value];
        collector.collection.push(collector.currentCommandArgs);
        collector.currentCommandArgs = newCommandArgs;
      } else if (collector.currentCommandArgs.length == 2) {
        const newCommandArgs = ['ROOT', value];
        collector.collection.push(collector.currentCommandArgs);
        collector.currentCommandArgs = newCommandArgs;
      } else {
        collector.currentCommandArgs.push(value);
      }
      return collector;
    }, {
      currentCommandArgs: ['ROOT'],
      collection: []
    });
    const groupedByCommandsArr = groupedByCommands.collection.concat([groupedByCommands.currentCommandArgs]).filter(commandPair => commandPair.length != 1);
    return groupedByCommandsArr;
  },
  getRubyComponentAtPath: function (path) {
    const chainedSelectorSpec = this._chainedSelectorSpecificationFromPathString(path);
    function findNodeFromTargetNode_inDirection_atSelector(targetNode, direction, selector) {
      //# direction = 'ancestor' || 'descendent'

      const selectorString = selector.substr(1);
      const selectorTypeChar = selector.charAt(0);
      if (selectorTypeChar == '#') {
        if (direction == 'ancestor') {
          return targetNode.findAncestorBy(rcNode => rcNode.getID() == selectorString);
        } else {
          return targetNode.findDescendentBy(rcNode => rcNode.getID() == selectorString);
        }
      } else if (selectorTypeChar == '[') {
        //# looking for attribute existence
        const attributeString = _.trim(selectorString.substring(0, selectorString.indexOf(']')));
        const [isEqSelector, keyPath, val] = /([^=]+)=(.+)/.exec(attributeString) || [];
        const selector = isEqSelector ? rcNode => _.get(rcNode, keyPath) == val : rcNode => !_.isNil(_.get(rcNode, attributeString));
        if (direction == 'ancestor') {
          return targetNode.findAncestorBy(selector);
        } else {
          return targetNode.findDescendentBy(selector);
        }
      } else {
        //# assume componentName
        if (direction == 'ancestor') {
          return targetNode.findAncestorBy(rcNode => rcNode.componentName == selectorString);
        } else {
          return targetNode.findDescendentBy(rcNode => rcNode.componentName == selectorString);
        }
      }
    }
    const selectedNode = chainedSelectorSpec.reduce((currentNode, commandPair) => {
      const [command, selector] = commandPair;
      switch (command) {
        case 'ROOT':
          const rootNode = currentNode.getRoot();
          return findNodeFromTargetNode_inDirection_atSelector(rootNode, 'descendent', selector);
        case '<':
          return findNodeFromTargetNode_inDirection_atSelector(currentNode, 'ancestor', selector);
        case '>':
          return findNodeFromTargetNode_inDirection_atSelector(currentNode, 'descendent', selector);
        default:
          return currentNode;
      }
    }, this);
    return selectedNode;
  },
  getKeypathArr: function () {
    var keypathArr = [];
    var currentParent = this;
    do {
      keypathArr.push(currentParent.getID());
      currentParent = currentParent.getParent();
    } while (!_.isNil(currentParent));
    return _.reverse(keypathArr);
  },
  getID: function () {
    return this.props.id || this.componentName;
  }

  //# _dependenciesByID: undefined
  ,
  getDependencies: function () {
    if (this._dependenciesByID) {
      return this._dependenciesByID;
    }
    var dependenciesByID;
    if (_.isArray(this.dependencies)) {
      dependenciesByID = Object.assign.apply(null, this.dependencies.reduce((collector, dependenciesFn) => {
        collector.push(_.isFunction(dependenciesFn) ? dependenciesFn.apply(this) : dependenciesFn);
        return collector;
      }, [{}]));
    } else if (typeof this.dependencies == 'function') {
      dependenciesByID = this.dependencies.apply(this);
    } else {
      //# never allow the module to redefine the selector
      dependenciesByID = this.dependencies;
    }

    //# Check for nil then don't cache so that later calls will get updated values
    const containsNil = _.reduce(dependenciesByID, (hasNil, dependency, id) => {
      if (hasNil) {
        return hasNil;
      }
      const dependencyIsNil = _.isNil(dependency);
      /*
      if (dependencyIsNil) {
          console.log('=== component', this.getID(), 'has nil dependency:', id);
      }
      */

      return dependencyIsNil;
    }, false);
    if (!containsNil) {
      this._dependenciesByID = dependenciesByID;
    }
    return dependenciesByID;
  },
  clearDependencies: function () {
    this._dependenciesByID = undefined;
  },
  _cachedStatesSelector: undefined
  //# one selector that is given the application state and should return all of the local states that you need
  //# by key
  //# By default, it's just {self}
  ,
  getStatesSelector() {
    if (this._cachedStatesSelector) {
      return this._cachedStatesSelector;
    }
    if (this.statesSelector) {
      this._cachedStatesSelector = this.statesSelector.bind(this);
    } else {
      const selfStateSelector = this.getSelfStateSelector();
      this._cachedStatesSelector = state => ({
        self: selfStateSelector(state)
      });
    }
    return this._cachedStatesSelector;
  }

  //== NOTIFICATIONS ==============================================//
  ,
  closestNotificationComponent: function () {
    let rubyApp = this.getRoot();
    //# look for closest ancestral notification component
    //# (previously wanted to look for descendent, which is not a good idea)
    const ancestorContainingClosestNotificationCousin = this.findAncestorBy(node => {
      return node.findChildBy(child => child.componentName == 'rubyComponentNotifications');
    });
    let rubyNotificationComponent = ancestorContainingClosestNotificationCousin.findChildBy(child => child.componentName == 'rubyComponentNotifications');
    if (!rubyNotificationComponent) {
      rubyNotificationComponent = rubyApp.findDescendentByID('rubyComponentNotifications');
    }
    return rubyNotificationComponent;
  },
  pushNotification: function (options) {
    const rubyNotificationComponent = this.closestNotificationComponent();
    if (rubyNotificationComponent) {
      let store = this.getStore();
      let notificationOptions = _extends({}, options, {
        modal: false
      });
      let notificationActionGenerator = rubyNotificationComponent.getAction().generators.showNotification(notificationOptions);
      store.dispatch(notificationActionGenerator);
    }
  },
  showErrorNotification: function (options) {
    const rubyNotificationComponent = this.closestNotificationComponent();
    if (rubyNotificationComponent) {
      let store = this.getStore();
      let notificationActionGenerator = rubyNotificationComponent.getAction().generators.showErrorNotification(options);
      store.dispatch(notificationActionGenerator);
    }
  },
  showNotificationModal: function (options) {
    const rubyNotificationComponent = this.closestNotificationComponent();
    if (rubyNotificationComponent) {
      let store = this.getStore();
      let notificationOptions = _extends({}, options, {
        modal: true
      });
      let notificationActionGenerator = rubyNotificationComponent.getAction().generators.showNotification(notificationOptions);
      store.dispatch(notificationActionGenerator);
    }
  }

  //== REDUX ======================================================//
  ,
  middleware: undefined,
  getMiddleware: function () {
    const boundMiddlewareArray = _.isArray(this.middleware) ? _.map(this.middleware, middleware => middleware.bind(this)) : this.middleware ? [this.middleware.bind(this)] : [];
    return boundMiddlewareArray;
  },
  getMiddlewares: function () {
    return this.getMiddleware();
  },
  getMiddlewares_flatMapped: function () {
    var children = this.getChildren();
    var childrenMiddlewares = _.flatMap(children, function (children) {
      return children.getMiddlewares_flatMapped();
    });
    if (_.isNil(childrenMiddlewares)) {
      childrenMiddlewares = [];
    }
    var selfMiddlewares = this.getMiddlewares();
    if (selfMiddlewares.length > 0) {
      childrenMiddlewares = selfMiddlewares.concat(childrenMiddlewares);
    }
    return childrenMiddlewares;
  }
  //, getInitialState: function(state, childrenState) {}
  ,
  reducer: function () {
    let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let action = arguments.length > 1 ? arguments[1] : undefined;
    return state;
  } //# DEFAULT REDUCER
  //# could also be:
  //# reducer: [function(){} .... function(){}]
  //# and getReducerByKey will sideways combine the reducers
  //# TODO
  //# options: {children} //# allows us to replace the children
  ,
  getReducerByKey: function () {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const self = this;
    const {
      children = self.getChildren()
    } = options;
    let childrenReducersByKey = _.reduce(children, function (collector, child) {
      const childReducerByKey = child.getReducerByKey();
      if (childReducerByKey) {
        collector = _.assign(collector, childReducerByKey);
      }
      return collector;
    }, {});
    const childrenReducersKeys = Object.keys(childrenReducersByKey);

    //# NOTE: we assume it's an array since the createElement() call will return arrays regardless of whether there are reducers
    const wrappedSelfReducer = function (selfState, action, childrenState) {
      const selfReducer = this.reducer;
      if (selfState == undefined && this.getInitialState) {
        selfState = this.getInitialState(selfState, childrenState);
      }
      const finalState = _.reduce(selfReducer, (collector, reducer) => {
        const reducedState = reducer.call(this, collector, action);
        return reducedState;
      }, selfState);
      return finalState;
    }.bind(self);
    const hasSelfReducer = this.reducer.length > 0;
    if (childrenReducersKeys.length) {
      var combinedChildrenReducer = (0, _redux.combineReducers)(childrenReducersByKey);
      return {
        [this.getID()]: function (state, action) {
          var selfState = _.omit(state, childrenReducersKeys);
          if (Object.keys(selfState).length === 0) {
            selfState = undefined;
          }

          //# NOTE:
          //# we pass selfReducerOutput to the children to allow for the parent to have overridden the state values (this requires an object assign of state and selfReducerOutput
          //# So we opt for this idea: to switch the order of the object assign to have the parent override the children
          //# ALSO NOTE: we need to rely on es6 function parameter defaults to know when to default the state, so we can't pass down children state to the wrappedSelfReducer

          var childrenState = _.pick(state, childrenReducersKeys);
          if (Object.keys(childrenState).length === 0) {
            childrenState = undefined;
          }
          var childrenReducerOutput = combinedChildrenReducer(childrenState, action);
          var selfReducerOutput = wrappedSelfReducer(selfState, action, childrenReducerOutput);
          return _.assign({}, childrenReducerOutput, selfReducerOutput);
        }
      };
    } else if (hasSelfReducer) {
      return {
        [this.getID()]: wrappedSelfReducer //# make sure we keep context of the ruby-component element instance
      };
    } else {
      return undefined;
    }
  },
  action: {
    //# DEFAULT ACTION
    TYPES: {},
    generators: {}
  }
  //# action could also be:
  //# action: [fn || {}, ... fn || {}]
  //# and getAction() will hydrate it
  ,
  _hydratedAction: undefined,
  _mergedActionArray_usingContext: function (actionArray, context) {
    const allActionsInBuckets = _.reduce(actionArray, (collector, actionObject) => {
      const hydratedActionObj = typeof actionObject == 'function' ? actionObject.apply(context) : actionObject;
      collector.TYPES.push(hydratedActionObj.TYPES);
      collector.generators.push(hydratedActionObj.generators);
      return collector;
    }, {
      TYPES: [],
      generators: []
    });

    //# TODO: set meta property to actions to include _parent: [actions .... ] where the rhs is the latest action being overridden
    const mergedAction = {
      TYPES: _extends({}, ...allActionsInBuckets.TYPES),
      generators: _extends({}, ...allActionsInBuckets.generators)
    };
    const actionParentsByKey = {};
    //# TODO: see if we can use proxies to automatically detect object assign overrides
    _.forEach(mergedAction.generators, (generator, generatorKey) => {
      actionParentsByKey[generatorKey] = allActionsInBuckets.generators.reduce((collector, actionBucket) => {
        const actionGenerator = actionBucket[generatorKey];
        if (actionGenerator) {
          collector.push(actionGenerator);
        }
        return collector;
      }, []).slice(0, -1);
    });
    self._actionParentsByKey = actionParentsByKey; //# need to store it here, but we'll set it in the bind call in getAction

    return {
      mergedAction,
      actionParentsByKey
    };
  },
  getAction: function () {
    let self = this;
    if (self._hydratedAction) {
      return self._hydratedAction;
    }

    //# hydrate action if it's a function
    //# this allows us to inject the 'this' context into the action generator function
    let actionObject;
    const actionType = typeof self.action;
    const {
      mergedAction: origActionObject,
      actionParentsByKey = {}
    } = actionType == 'function' ? {
      mergedAction: self.action.apply(self)
    } : _.isArray(self.action) ? self._mergedActionArray_usingContext(self.action, self) : {
      mergedAction: self.action
    };

    //# Auto-bind every generator to `this`
    let boundActionObjectGenerators = _.reduce(origActionObject.generators, function (collector, generator, generatorKey) {
      collector[generatorKey] = generator.bind(self);
      collector[generatorKey]._parents = actionParentsByKey[generatorKey];
      return collector;
    }, {});
    actionObject = _.assign({}, origActionObject, {
      generators: boundActionObjectGenerators
    });
    const mergedActionTypes = _.assign({}, actionObject.TYPES);
    const mergedActionGenerators = _.assign({}, actionObject.generators);
    actionObject = _.assign(actionObject, {
      TYPES: mergedActionTypes,
      generators: mergedActionGenerators
    });
    self._hydratedAction = actionObject;
    return self._hydratedAction;
  },
  selector: {},
  _hydratedSelector: undefined,
  _defaultSelector: {
    default: function (state) {
      return _.get(state, this.getKeypathArr());
    },
    selfState: function (state) {
      const childrenIds = this.getChildrenIds();
      return _.omit(_.get(state, this.getKeypathArr()), childrenIds);
    },
    childrenState: function (state) {
      const childrenIds = this.getChildrenIds();
      return _.pick(_.get(state, this.getKeypathArr()), childrenIds);
    }
  },
  _boundDefaultSelector_default: undefined,
  _boundDefaultSelector_selfState: undefined,
  _boundDefaultSelector_childrenState: undefined,
  getDefaultSelector: function () {
    if (this._boundDefaultSelector_default) {
      return this._boundDefaultSelector_default;
    }
    this._boundDefaultSelector_default = this._defaultSelector.default.bind(this);
    return this._boundDefaultSelector_default;
  },
  getSelfStateSelector: function () {
    if (this._boundDefaultSelector_selfState) {
      return this._boundDefaultSelector_selfState;
    }
    this._boundDefaultSelector_selfState = this._defaultSelector.selfState.bind(this);
    return this._boundDefaultSelector_selfState;
  },
  getChildrenStateSelector: function () {
    if (this._boundDefaultSelector_childrenState) {
      return this._boundDefaultSelector_childrenState;
    }
    this._boundDefaultSelector_childrenState = this._defaultSelector.childrenState.bind(this);
    return this._boundDefaultSelector_childrenState;
  }
  //# returns an object containing different selectors (of which one is the defaultSelector)
  //# not really used anymore by newer components. Try not to use it
  ,
  getSelector: function () {
    //# autobind the selector
    var self = this;
    //# hydrate selector if it's a function
    //# this allows us to inject the 'this' context into the selector generator function
    var selectorObject;
    if (typeof self.selector == 'function') {
      selectorObject = _.assign({}, self.selector.apply(self), self._defaultSelector);
    } else {
      //# never allow the module to redefine the selector
      selectorObject = _.assign({}, self.selector, self._defaultSelector);
    }
    self._hydratedSelector = _.reduce(selectorObject, function (collector, selector, selectorKey) {
      collector[selectorKey] = selector.bind(self);
      return collector;
    }, {});
    return self._hydratedSelector;
  },
  getSelectorRelativeToComponent: function (ancestorComponent) {
    const relativeComponentKeypathArr = ancestorComponent.getKeypathArr();
    const selfKeypathArr = this.getKeypathArr();
    const relativeComponentPathMatches = relativeComponentKeypathArr.reduce((isMatch, value, index) => {
      if (!isMatch) {
        return false;
      }
      return isMatch && selfKeypathArr[index] == value;
    }, true);
    if (!relativeComponentPathMatches) {
      throw new Error('Component keypath is completely different from this module\'s keypath. It makes no sense to be calling this method');
    }
    const relativeKeypathArr = selfKeypathArr.slice(relativeComponentKeypathArr.length);
    return stateOfComponent => {
      return _.get(stateOfComponent, relativeKeypathArr);
    };
  },
  _injectedConnectorSelectorsByKey: {}
  //# all selectors are passed the root-level state and are expected to return the root-level state
  //# DEPRECATED 20180926 - not used. It was previously used by the plugin-content-approval module
  ,
  registerConnectorSelector_forKey: function (connectorSelector, callerKey) {
    this._injectedConnectorSelectorsByKey[callerKey] = connectorSelector;
  }
  //# DEPRECATED 20180926 - not used. It was previously used by the plugin-content-approval module
  ,
  getConnectorSelector: function () {
    var connectorSelectorsByKey = this._injectedConnectorSelectorsByKey;
    return function (state) {
      if (Object.keys(connectorSelectorsByKey).length === 0) {
        return state;
      }
      var collectorInitialState = _.assign({}, state);
      var retVal = _.reduce(connectorSelectorsByKey, function (collector, connectorSelector) {
        return connectorSelector(collector);
      }, collectorInitialState);
      return retVal;
    };
  }

  //# returns [[<ReactElement/>],[<ReactElement/>]]
  ,
  getChildrenReactElements() {
    let childrenElements = this.getChildren();
    if (this._childrenReactElements) {
      return this._childrenReactElements;
    }
    let headerReactElements = [];
    let footerReactElements = _.reduce(childrenElements, function (collector, childElement) {
      if (childElement.getReactElement && !childElement.getRouteElement) {
        let childReactElement = childElement.getReactElement();
        if (childElement._ownedIndex >= 0) {
          headerReactElements.push(childReactElement);
        } else {
          collector.push(childReactElement);
        }
      }
      return collector;
    }, []);
    const childrenReactElements = [headerReactElements, footerReactElements];
    this._childrenReactElements = childrenReactElements;
    return childrenReactElements;
  }

  //== REACT-centric METHODS ==========================================//
  //# getReactClass() 
  //# if you have a main reactClass that you want associated with this ruby-component
  //# you *must* define a getReactClass() function

  //# getReactElement() 
  //# if this ruby-component is a nestable component with react elements 
  //# that you want inserted into the parent ruby-component's react element
  //# you *must* define a getReactElement() function

  //# getRouteElement()
  //# if this ruby-component manages routes with react-router instead of 
  //# reactElements, you *must* define a getRouteElement()
  //# that returns an instance of <Route component={this.getReactClass()}/>
  ,

  _routePath: undefined,
  _routeObject: undefined
  /**
   * @function getRoutePath
   *
   * @return {string} The joined path of the nearest parent route (which may include itself) and all of its ancestors
   */,
  getRoutePath() {
    if (this._routePath) {
      return this._routePath;
    }
    let routePathArr = [];
    this.iterativelyTraverseAncestors_withCallback(rubyComponent => {
      const currPath = rubyComponent.props.path;
      if (currPath) {
        routePathArr.unshift(currPath);
      }
    });
    const routePath = path.join.apply(path, routePathArr);
    this._routePath = routePath;
    return routePath;
  }
  /**
   * @function getRoutePathParser
   *
   * @return {instanceOf(Route)} the new Route(this.getRoutePath()) instance
   */,
  getRoutePathParser() {
    if (this._routeObject) {
      return this._routeObject;
    }
    const routePath = this.getRoutePath();
    this._routeObject = new Route(routePath);
    return this._routeObject;
  }

  //== LIFECYCLE Methods ======================================//
  //# the lifecycle methods are called in bulk (all children components) in this order
  // , onMount: function() {}
  // , onUnmount: function() {}
  // , _onReduxInit_returnValues: [] //# might contain store-unsub function, which we need to call on
  // , onReduxInit: function(dispatch) {}
  // , onReduxTearDown: function() {} //# don't need to define this, RubyComponent will auto-wrap if there's an onReduxInit method
  // , onReactInit: function() {}
  // , onBeforeUnload: function() {} //# triggered on a tab/window unload
  ,
  triggerTree_onMount: function () {
    var selfArguments = arguments;
    this.iterativelyTraverseChildren_withCallback(function (element) {
      if (element.onMount) {
        element.onMount.apply(element, selfArguments);
      }
    }, false);
  },
  triggerTree_onUnmount: function () {
    var selfArguments = arguments;
    this.iterativelyTraverseChildren_withCallback(function (element) {
      if (element.onUnmount) {
        element.onUnmount.apply(element, selfArguments);
      }
    }, false);
  },
  triggerTree_onReduxInit: function (dispatch) {
    var selfArguments = arguments;
    this.iterativelyTraverseChildren_withCallback(function (element) {
      if (element.onReduxInit) {
        element.onReduxInit.apply(element, selfArguments);
      }
    }, false);
  },
  triggerTree_onReduxTearDown: function () {
    var selfArguments = arguments;
    this.iterativelyTraverseChildren_withCallback(function (element) {
      if (element.onReduxTearDown) {
        element.onReduxTearDown.apply(element);
      }
    }, false);
  },
  triggerTree_onReactInit: function () {
    var selfArguments = arguments;
    this.iterativelyTraverseChildren_withCallback(function (element) {
      if (element.onReactInit) {
        element.onReactInit.apply(element, selfArguments);
      }
    }, false);
  }

  //== TEARDOWN =======================================================//
  ,
  clearCache: function () {
    //# NOTE: only being used to clear cache in cloned elements.
    //# May need to reconsider naming if we use this for something else
    this._hydratedAction = undefined;
    this._routePath = undefined;
    this._routeObject = undefined;
  }

  //== HELPER METHODS ==============================================//

  //# sometimes we need to set stateful values in a way where 
  /**
   * set value on RubyComponent at key
   * @param {String|Array} key - key can be a keypath if you want to set a nested state.
   *  */,
  setStatefulCacheForKey(key, value) {
    return _.set(this, ['_statefulCache'].concat(key), value);
  },
  getStatefulCacheForKey(key) {
    return _.get(this, ['_statefulCache'].concat(key));
  },
  clearStatefulCacheForKey(key) {
    return _.unset(this, ['_statefulCache'].concat(key));
  },
  clearStatefulCacheForKey(key) {
    return _.unset(this, ['_statefulCache'].concat(key));
  },
  clearStatefulCache() {
    this._statefulCache = undefined;
  }
};
module.exports = baseElement;