"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = FieldsetConnectorGenerator;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _mustache = _interopRequireDefault(require("mustache"));
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const rubyWords = require('../../../../ruby-words');
function FieldsetConnectorGenerator(Component) {
  const selfModule = this;
  const {
    selfSelector
  } = selfModule.getDependencies();
  const selfKey = this.key();
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const {
      interpolateLabel,
      label: rawLabel
    } = ownProps;
    const view = !interpolateLabel ? {} : _extends({
      data: selfKey ? selfModule.formValue()[selfKey] : selfModule.formvValue(),
      modules: {
        rubyWords
      }
    });
    let interpolatedLabel = rawLabel;
    if (rawLabel && interpolateLabel) {
      try {
        switch (interpolateLabel) {
          case 'lodash':
            interpolatedLabel = _lodash.default.template(rawLabel)(view);
            break;
          case true:
          case 'mustache':
            interpolatedLabel = _mustache.default.render(rawLabel, view);
            break;
          default:
            interpolatedLabel = rawLabel;
            break;
        }
      } catch (err) {
        console.warn('Failed to interpolate Dynamic Name label, will use the raw label instead. See full error below:');
        console.warn(err);
      }
    }
    const mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
    const formattedError = selfModule.formattedErrorFromState(state);
    const mappedProps = _extends({}, selfState, ownProps, mixinFieldProps, formattedError, {
      descendentsHaveValues: selfModule.doesDescendentsHaveValuesFromForm(selfModule.formValue()),
      label: interpolatedLabel,
      children: ownProps.getMergedChildrenReactElements ? ownProps.getMergedChildrenReactElements() : ownProps.children
    });

    //# hydrate actionButtons
    if (mappedProps.actionButtons) {
      mappedProps.actionButtons = selfModule.hydratedActionButtons(mappedProps.actionButtons);
    }
    return mappedProps;
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(Component);
}