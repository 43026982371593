"use strict";

const PREFERENCES_TAB = {
  "children": [{
    "children": [{
      "label": "Preferences Info",
      "componentName": "Info",
      value: '{{^config.RUBY_BRANDING}}Loading...{{/config.RUBY_BRANDING}}{{#config.RUBY_BRANDING}}{{config.RUBY_BRANDING}} stores certain user preferences on your browser, such as lister columns selected and queries performed. The next time you visit the lister, they will be restored. If you want to reset all of your user preferences, you may do so by clicking the button below.{{/config.RUBY_BRANDING}}',
      interpolateValue: true,
      childrenWrapperStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        paddingTop: 0
      },
      children: [{
        "action": {
          "action": "clearLocalPreferences",
          "path": "#rubyComponentCurrentUser"
        },
        rubyIconPath: 'material-ui/svgIcons/ActionCached',
        "componentName": "Button",
        "label": "Clear User Preferences"
      }]
    }],
    "componentName": "Fieldset"
  }],
  "label": "Preferences",
  "componentName": "Tab"
};
module.exports = function (template, callingModule) {
  const {
    key: templateKeyword
  } = template;
  if (templateKeyword != 'user') {
    return template;
  }
  const {
    routeParams: {
      id: userId
    } = {}
  } = callingModule.getState() || {};
  const {
    id: currentUserId
  } = this.getCurrentUserState();
  if (userId != currentUserId) {
    return template;
  }

  //# add PREFERENCES_TAB to the end 
  template.form.children.push(PREFERENCES_TAB);
  return template;
};